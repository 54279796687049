<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Presentacion"
            label-for="presentacion"
          >
            <v-select
              id="presentacion"
              v-model="presentacionSel"
              label="descripcion"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="presentaciones"
            />
          </b-form-group>

          <!-- Stock -->
          <validation-provider
            #default="validationContext"
            name="Stock Actual"
            rules="required"
          >
            <b-form-group
              label="Stock Actual"
              label-for="stockActual"
            >
              <b-input-group
                append="UND"
              >
                <b-form-input
                  id="stockActual"
                  v-model="formData.stockActual"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />
              </b-input-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Precio -->
          <validation-provider
            #default="validationContext"
            name="Precio General"
            rules="required"
          >
            <b-form-group
              label="Precio General"
              label-for="precioDefault"
            >
              <b-input-group
                prepend="S/."
              >
                <b-form-input
                  id="precioDefault"
                  v-model="formData.precioDefault"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />
              </b-input-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Precio Compra -->
          <validation-provider
            #default="validationContext"
            name="Precio Compra"
            rules="required"
          >
            <b-form-group
              label="Precio Compra"
              label-for="precioCompra"
            >
              <b-input-group
                prepend="S/."
              >
                <b-form-input
                  id="precioCompra"
                  v-model="formData.precioCompra"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />
              </b-input-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BInputGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const blankData = {
      presentacion: null,
      stockActual: 0,
      precioDefault: 0,
      precioCompra: 0,
    }
    const presentaciones = ref([])
    const presentacionSel = ref({})

    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }

    const getPresentacion = async () => {
      await store.dispatch('catalogo/PRESENTACION_FIND_ALL', {
        query: '',
        page: -1,
        limit: -1,
        tipo: 'cmb',
        sortBy: 'idPresentacion%7CASC',
      })
        .then(response => {
          if (response) {
            response.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.descripcion = `${item.descripcion} - ${item.marca.descripcion}`
            })
            presentaciones.value = response
          }
        })
    }

    const onSubmit = () => {
      if (presentacionSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar presentación del producto.' } } })
        return
      }
      let service = 'comercial/PRODUCTO_CREATE'
      if (props.formType === 'edit') {
        service = 'comercial/PRODUCTO_UPDATE'
      }
      formData.value.presentacion = presentacionSel.value
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = val => {
      emit('update:is-toggle-sidebar', val)
      presentacionSel.value = {}
      resetData()
      getPresentacion()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Producto'
      }
      if (props.formType === 'edit') {
        formData.value = {
          idProducto: props.dataEdit.idProducto,
          presentacion: props.dataEdit.presentacion,
          stockActual: props.dataEdit.stockActual,
          precioDefault: props.dataEdit.precioDefault,
          precioCompra: props.dataEdit.precioCompra,
          estado: props.dataEdit.estado,
        }
        presentacionSel.value = props.dataEdit.presentacion
        titleForm.value = 'Editar Producto'
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      toggleSidebar,
      titleForm,
      getPresentacion,
      presentaciones,
      presentacionSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
